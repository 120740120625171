import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import { useStyles } from "./ProductsSelection.styles";

interface ManualProductsListItemProps {
	product: any;
	selected?: boolean;
	handleCheck: (productId: string) => void;
}

export default function ManualProductsListItem({
	product,
	selected,
	handleCheck
}: ManualProductsListItemProps) {
	const classes = useStyles();

	return (
		<TableRow>
			<TableCell>
				<Checkbox
					checked={selected}
					onChange={() => handleCheck(product._id)}
					color="primary"
				/>
			</TableCell>
			<TableCell>{product.name}</TableCell>
			<TableCell>{product.size}</TableCell>
			<TableCell>{product.supplier}</TableCell>
			<TableCell>{product.brand}</TableCell>
			<TableCell>{product.premise}</TableCell>
			<TableCell>{product.packageType}</TableCell>
			<TableCell>{product.tags}</TableCell>
			{/* <TableCell>{product.units}</TableCell> */}
			{/* <TableCell>{product.brandFam ? product.brandFam : "-"}</TableCell> */}
		</TableRow>
	);
}
