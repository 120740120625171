import {
	DialogContent,
	Button,
	Divider,
	Grid,
	Typography,
} from "@material-ui/core";
import { useState } from "react";
import { useStyles } from "./EditUserTargetPayout.styles";
import { FormProvider, useForm } from "react-hook-form";
import { TargetComponent } from "../../usersManagement/objectivesIncentivesComponent/editObjIncComponents/targetComponent";
import { PayoutDetails } from "../../usersManagement/objectivesIncentivesComponent/editObjIncComponents/utils";
import { PayoutComponent } from "../../usersManagement/objectivesIncentivesComponent/editObjIncComponents/payoutComponents";
import { IRow } from "../Item/ItemManagementReportTable";
import MinimumQuantityComponent from "../../usersManagement/objectivesIncentivesComponent/editObjIncComponents/minimumQuantityComponent/MinimumQuantityComponent";

interface EditUserTargetPayoutProps {
	username?: string;
	initialPayoutDetails: PayoutDetails;
	onSave: (payoutDetails: PayoutDetails, minimumQuantity: number) => void;
	editRow?: IRow;
	initialMinQuantityValue?: number;
}

export const EditUserTargetPayout = ({
	username,
	initialPayoutDetails,
	onSave,
	editRow,
	initialMinQuantityValue,
}: EditUserTargetPayoutProps) => {
	const classes = useStyles();
	const methods = useForm();
	const [payoutDetails, setPayoutDetails] =
		useState<PayoutDetails>(initialPayoutDetails);
	const [minQuantityValue, setMinQuantityValue] = useState(
		initialMinQuantityValue || 0
	);

	const handleMinQuantityValueChange = (newValue: number) => {
		setMinQuantityValue(newValue);
	};

	const handleTargetChange = (newTarget: number) => {
		setPayoutDetails((prev) => ({
			...prev,
			target: newTarget,
		}));
	};

	const handleIsOpenEndedChange = (newIsOpenEnded: boolean) => {
		setPayoutDetails((prev) => ({
			...prev,
			isOpenEnded: newIsOpenEnded,
			payoutType: "perAction",
		}));
	};

	const handleSave = () => {
		onSave(payoutDetails, minQuantityValue);
	};

	return (
		<FormProvider {...methods}>
			<Typography variant="h6" className={classes.title}>
				Edit {username}
			</Typography>
			<div className={classes.container}>
				<div className={classes.targetPayout}>
					<DialogContent className={classes.target}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<Typography variant="body1">
									<strong>Name:</strong>{" "}
									{editRow?.incentive || editRow?.objective}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography variant="body1">
									<strong>User Type:</strong> {editRow?.userType || "N/A"}
								</Typography>
							</Grid>

							<Grid item xs={12} sm={6}>
								<Typography variant="body1">
									<strong>Start Date:</strong>{" "}
									{editRow?.startDate
										? new Date(editRow.startDate).toLocaleDateString()
										: "N/A"}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography variant="body1">
									<strong>Type:</strong> {editRow?.type}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography variant="body1">
									<strong>End Date:</strong>{" "}
									{editRow?.endDate
										? new Date(editRow.endDate).toLocaleDateString()
										: "N/A"}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography variant="body1">
									<strong>Route:</strong> {editRow?.route || "N/A"}
								</Typography>
							</Grid>
						</Grid>
						<TargetComponent
							name="Target"
							target={payoutDetails.target}
							isOpenEnded={payoutDetails.isOpenEnded}
							onTargetChange={handleTargetChange}
							onIsOpenEndedChange={handleIsOpenEndedChange}
							itemType={editRow?.type}
						/>
					</DialogContent>
					{editRow?.type === "imageValidation" && (
						<DialogContent className={classes.payout}>
							<MinimumQuantityComponent
								minQuantityValue={minQuantityValue}
								onMinQuantityValueChange={handleMinQuantityValueChange}
							/>
						</DialogContent>
					)}
					<Divider />
					<DialogContent>
						<PayoutComponent
							payoutDetails={payoutDetails}
							setPayoutDetails={setPayoutDetails}
							itemType={editRow?.type}
						/>
					</DialogContent>
				</div>
				<div className={classes.buttons}>
					<Button onClick={() => methods.reset()} color="primary">
						Cancel
					</Button>
					<Button onClick={methods.handleSubmit(handleSave)} color="primary">
						Save
					</Button>
				</div>
			</div>
		</FormProvider>
	);
};
