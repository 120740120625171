import { useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import ManualProductsListItem from "./ManualProductListItem";
import {
    Button,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import usePaginatedProducts from "../../../../hooks/usePaginatedProducts";
import { Item, ManualProductsFilters, isIncentive } from "../../../../types/commonObjIncTypes";
import snack from "../../../../../../utils/snack";
import { useStyles } from "./ProductsSelection.styles";
import useManualProducts from "../../../../hooks/useManualProducts";

interface AllProductsComponentProps {
    item: Item;
    filters: ManualProductsFilters;
    allSelected: boolean;
    clearSelection: boolean;
    setAllSelected: React.Dispatch<React.SetStateAction<boolean>>;
    setClearSelection: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AllProductsComponent({
    item,
    filters,
    allSelected,
    clearSelection,
    setAllSelected,
    setClearSelection
}: AllProductsComponentProps) {
    const classes = useStyles();

    const [currentPage, setCurrentPage] = useState(1);
    const { allProducts, totalPages, productsLoading } = usePaginatedProducts({
        page: currentPage,
        item: item,
        filters: filters,
    });

    const [selectedProds, setSelectedProds] = useState<
        { productId: string; checked: boolean }[]
    >([]);

    const { updateProducts } = useManualProducts();

    const handleSave = (showSnack = false) => {
        const productsToUpdate = selectedProds.map((product) => ({
            productId: product.productId,
            checked: product.checked,
        }));
        updateProducts(item._id, productsToUpdate, !isIncentive(item));
        if (showSnack) {
            snack.success("Products updated 👍");
        }
    };

    useEffect(() => {
        const initialSelections = allProducts;
        const selections = initialSelections.map((prod) => ({
            productId: prod.customId,
            checked: prod.isSelected || false,
        }));

        setSelectedProds(selections);
    }, [allProducts]);

    useEffect(() => {
        if (allSelected) {
            setSelectedProds((prev) =>
                prev.map((prod) => ({ ...prod, checked: true }))
            );
        }
    }, [allSelected]);

    useEffect(() => {
        if (clearSelection) {
            setSelectedProds((prev) =>
                prev.map((prod) => ({ ...prod, checked: false }))
            );
        }
    }, [clearSelection]);

    const handlePageChange = (_event: any, page: number) => {
        handleSave();
        setCurrentPage(page);
        setAllSelected(false);
        setClearSelection(false);
    };

    const loading = productsLoading;

    return (
        <div className={classes.listContainer}>
            <div className={classes.content}>
                {loading ? (
                    <div className={classes.progress}>
                        <CircularProgress size={"1rem"} />
                    </div>
                ) : allProducts.length === 0 ? (
                    <Typography
                        className={classes.notFound}
                        variant="body1"
                    >
                        No products found
                    </Typography>
                ) : (
                    <Paper className={classes.table}>
                        <TableContainer className={classes.tableContainer}>
                            <Table aria-label="products table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Select</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Supplier</TableCell>
                                        <TableCell>Brand</TableCell>
                                        <TableCell>Premise</TableCell>
                                        <TableCell>Package</TableCell>
                                        <TableCell>Tag</TableCell>
                                        {/* <TableCell>Units</TableCell> */}
                                        {/* <TableCell>Brand Family</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allProducts.map((prod) => (
                                        <ManualProductsListItem
                                            key={prod.customId}
                                            product={prod}
                                            selected={
                                                selectedProds.find(
                                                    (a) => a.productId === prod.customId
                                                )?.checked || false
                                            }
                                            handleCheck={() =>
                                                setSelectedProds((prev) =>
                                                    prev.map((a) =>
                                                        a.productId === prod.customId
                                                            ? {
                                                                ...a,
                                                                checked: !a.checked,
                                                            }
                                                            : a
                                                    )
                                                )
                                            }
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes.saveButtonContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleSave(true)}
                                className={classes.button}
                            >
                                SAVE
                            </Button>
                        </div>
                    </Paper>
                )}
            </div>
            <div className={classes.pagination}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
            </div>
        </div>
    );
}
