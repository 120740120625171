import { ImageListCell } from "./ImageListCell";
import { ProductsList } from "./ProductsList";
import AccountsList from "./AccountsList";
import { columnKeyMap, formatDate, formatTypeLabel } from "./utils";
import { useState } from "react";
import { TableCell } from "@material-ui/core";

const getStatusCell = (progress: number | string) => {
	let statusColor = "black";
	if (progress === "∞") {
		return <span>{progress}</span>;
	} else if (!isNaN(Number(progress))) {
		let progressNum = Number(progress);
		if (progressNum < 0) {
			progressNum = 0;
		}
		if (progressNum < 50) statusColor = "red";
		else if (progressNum >= 50 && progressNum < 80) statusColor = "#F4C01D";
		else if (progressNum >= 80) statusColor = "#4E915E";
		return <span style={{ color: statusColor }}>{`${progressNum}%`}</span>;
	}
	return <span style={{ color: "red" }}>{`0 %`}</span>;
};

const RouteCell = ({ route }: { route: string }) => {
	const [isHovered, setIsHovered] = useState(false);
	const maxLength = 20; // Adjust the max length as needed

	return (
		<div
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			style={{ position: "relative", display: "inline-block" }}
		>
			<span>
				{route !== undefined && route !== "undefined"
					? route.length <= maxLength || isHovered
						? route
						: `${route.substring(0, maxLength)}...`
					: "-"}
			</span>
		</div>
	);
};

export const mapRowToColumns = (
	row: any,
	columns: string[],
	classes?: any,
	onRowClick?: (row: any) => void
) => {
	const orderedRow: any[] = [];

	columns.forEach((column) => {
		const key = columnKeyMap[column];

		switch (column) {
			case "Start Date":
			case "End Date":
				orderedRow.push(formatDate(row[key] || ""));
				break;
			case "Status":
				orderedRow.push(getStatusCell(row.status || row.progress || "0"));
				break;
			case "Image":
				orderedRow.push(
					row.images?.length > 0 ? <ImageListCell images={row.images} /> : "-"
				);
				break;
			case "Accounts":
				orderedRow.push(
					<AccountsList
						itemId={row.itemId}
						userId={row.userId}
						isObjective={row.isObjective}
					/>
				);
				break;
			case "Products":
				orderedRow.push(
					<ProductsList
						itemId={row.itemId}
						userId={row.userId}
						isObjective={row.isObjective}
					/>
				);
				break;
			case "Input Value Type":
				const valueTypeMap: Record<string, string> = {
					gr: "Greater than",
					lt: "Less than",
					gre: "Greater than or equal",
					lte: "Less than or equal",
				};
				orderedRow.push(valueTypeMap[row[key]] || "-");
				break;
			case "Quantity":
				orderedRow.push(row[key] || "0");
				break;
			case "Minimum Quantity":
				orderedRow.push(row[key] || "0");
				break;
			case "Payout MTD":
				orderedRow.push(row[key] ? `$ ${row[key]}` : "$ 0");
				break;
			case "Weight":
				orderedRow.push(row[key] ? `$ ${row[key]}` : "$ 0");
				break;
			case "Potential":
				orderedRow.push(
					row[key] ? (row[key] !== "∞" ? `$ ${row[key]}` : "∞") : "0"
				);
				break;
			case "Route":
				orderedRow.push(<RouteCell route={row[key]} />);
				break;
			case "Type":
				orderedRow.push(formatTypeLabel(row[key]));
				break;
			default:
				orderedRow.push(row[key] || "-");
				break;
		}
	});

	if (!onRowClick) {
		return orderedRow;
	}

	return orderedRow.map((cellContent, index) => {
		if (["Accounts", "Products", "Image"].includes(columns[index])) {
			<TableCell key={index} className={classes.clickableRow}>
				{cellContent}
			</TableCell>;
		} else
			return (
				<TableCell
					key={index}
					onClick={() => onRowClick(row)}
					// className={classes.clickableRow}
					className={`${classes.clickableRow} ${
						columns[index] === "User Name"
							? classes.stickyColumn
							: columns[index] === "Route"
							? classes.stickyColumnLeft
							: classes.tableCell
					}`}
				>
					{cellContent}
				</TableCell>
			);
		return <TableCell key={index}>{cellContent}</TableCell>;
	});
};
