import { create } from "zustand";
import api from "../../../utils/api";
import {
	IAccountsPaginated,
	IGeneralQuery,
	IPaginatedQuery,
	IProductsPaginatedQuery,
	IQueryFavorite,
} from "../../../hooks/useQuery";
import { IBuyer } from "../../../interfaces";
import { Accounts } from "./objectivesStore";

interface Store {
	accounts: Accounts[];
	teamMembers: any;
	teamMembersToRemove: any;
	products: any;
	selectedProducts: any;
	selectedProductsTotalPages: number;
	selectedProductsLoading: boolean;
	selectedProductsCurrentPage: number;
	selectedAccounts: any;
	selectedAccountsTotalPages: number;
	selectedAccountsLoading: boolean;
	selectedAccountsCurrentPage: number;
	totalPages?: number;
	productsTotalPages: number;
	productsCurrentPage: number;
	productsLoading: boolean;
	allBuyers: IBuyer[];
	allProducts: any[];
	totalProdPages?: number;
	currentPage: number;
	secondaryTotalPages: number;
	secondaryLoading: boolean;
	buyersLoading: boolean;
	paginatedProductsLoading: boolean;
	setCurrentPage: (page: number) => void;
	setProductsCurrentPage: (page: number) => void;
	setProductsLoading: (productsLoading: boolean) => void;
	setSelectedProductsCurrentPage: (page: number) => void;
	setSelectedProductsLoading: (selectedProductsLoading: boolean) => void;
	setSelectedAccountsCurrentPage: (page: number) => void;
	setSelectedAccountsLoading: (selectedAccountsLoading: boolean) => void;
	fetchCustomersPaginated: (query: IAccountsPaginated) => void;
	fetchProductsPaginated: (query: IProductsPaginatedQuery) => void;
	fetchSelectedProducts: (query: IProductsPaginatedQuery) => void;
	fetchSelectedAccounts: (query: IAccountsPaginated) => void;
	setSecondaryLoading: (secondaryLoading: boolean) => void;
	setBuyersLoading: (buyersLoading: boolean) => void;
	setPaginatedProductsLoading: (paginatedProductsLoading: boolean) => void;
	updateAccounts: (
		isObjective: boolean,
		query: any,
		newAccounts: { buyerId: string; checked: boolean }[]
	) => void;
	updateProducts: (
		isObjective: boolean,
		query: any,
		newProducts: { productId: string; checked: boolean }[]
	) => void;
	fetchAccounts: (
		query: IQueryFavorite,
		itemId: string,
		userId: string,
		isObjective?: boolean,
		callback?: () => void
	) => void;
	fetchProducts: (
		query: IQueryFavorite,
		itemId: string,
		userId: string,
		isObjective?: boolean,
		callback?: () => void
	) => void;
	fetchTeamMembers: (query: IGeneralQuery, userId?: string) => void;
	fetchTeamMembersToRemove: (query: IGeneralQuery, userIds?: string[]) => void;
}

export const commonObjIncStoreZustand = create<Store>((set) => ({
	allBuyers: [],
	teamMembers: [],
	teamMembersToRemove: [],
	allProducts: [],
	selectedProducts: [],
	selectedProductsTotalPages: 1,
	selectedProductsLoading: false,
	selectedProductsCurrentPage: 1,
	selectedAccounts: [],
	selectedAccountsTotalPages: 1,
	selectedAccountsLoading: false,
	selectedAccountsCurrentPage: 1,
	productsTotalPages: 1,
	productsCurrentPage: 1,
	productsLoading: false,
	totalProdPages: 1,
	accounts: [],
	products: [],
	currentPage: 1,
	secondaryTotalPages: 1,
	secondaryLoading: false,
	buyersLoading: false,
	paginatedProductsLoading: false,
	setSelectedProductsCurrentPage: (page: number) =>
		set({ selectedProductsCurrentPage: page }),
	setSelectedProductsLoading: (selectedProductsLoading: boolean) =>
		set({ selectedProductsLoading: selectedProductsLoading }),
	setSelectedAccountsCurrentPage: (page: number) =>
		set({ selectedAccountsCurrentPage: page }),
	setSelectedAccountsLoading: (selectedAccountsLoading: boolean) =>
		set({ selectedAccountsLoading: selectedAccountsLoading }),
	setProductsLoading: (productsLoading: boolean) => set({ productsLoading }),
	setProductsCurrentPage: (page: number) => set({ productsCurrentPage: page }),
	setSecondaryLoading: (secondaryLoading: boolean) => set({ secondaryLoading }),
	setBuyersLoading: (buyersLoading: boolean) => set({ buyersLoading }),
	setPaginatedProductsLoading: (paginatedProductsLoading: boolean) =>
		set({ paginatedProductsLoading }),
	setCurrentPage: (page: number) => set({ currentPage: page }),
	fetchCustomersPaginated: async (query: IAccountsPaginated) => {
		set({ buyersLoading: true });
		let res;
		if (query.isObjective) {
			res = await api.fetch({
				path: `/api/v2/objective/${query.objectiveId}/buyers`,
				method: "GET",
				query,
			});
		} else {
			res = await api.fetch({
				path: `/api/v2/incentive/${query.incentiveId}/buyers`,
				method: "GET",
				query,
			});
		}

		if (res.ok === true) {
			set({
				allBuyers: res.payload,
				totalPages: res.meta?.totalPages,
			});
			set({ buyersLoading: false });
		} else {
			console.log("error fetching users");
			set({ allBuyers: [] });
			set({ buyersLoading: false });
		}
	},
	fetchProductsPaginated: async (query: IProductsPaginatedQuery) => {
		let res;
		set({ paginatedProductsLoading: true });
		if (query.isObjective) {
			res = await api.fetch({
				path: `/api/v2/objective/${query.objectiveId}/products`,
				method: "GET",
				query,
			});
		} else {
			res = await api.fetch({
				path: `/api/v2/incentive/${query.incentiveId}/products`,
				method: "GET",
				query,
			});
		}
		if (res.ok === true) {
			set({
				allProducts: res.payload.results,
				totalProdPages: res.payload.count,
			});
			set({ paginatedProductsLoading: false });
		} else {
			console.log("error fetching users");
			set({ allProducts: [] });
			set({ paginatedProductsLoading: false });
		}
	},
	fetchSelectedProducts: async (query: IProductsPaginatedQuery) => {
		let res;
		set({ selectedProductsLoading: true });
		if (query.isObjective) {
			res = await api.fetch({
				path: `/api/v2/objective/${query.objectiveId}/allProducts`,
				method: "GET",
				query,
			});
		} else {
			res = await api.fetch({
				path: `/api/v2/incentive/${query.incentiveId}/allProducts`,
				method: "GET",
				query,
			});
		}
		if (res.ok === true) {
			set({
				selectedProducts: res.payload.results,
				selectedProductsTotalPages: res.payload.count,
			});
			set({ selectedProductsLoading: false });
		} else {
			console.log("error fetching users");
			set({ selectedProducts: [] });
			set({ selectedProductsLoading: false });
		}
	},
	fetchSelectedAccounts: async (query: IAccountsPaginated) => {
		set({ selectedAccountsLoading: true });
		let res;
		if (query.isObjective) {
			res = await api.fetch({
				path: `/api/v2/objective/${query.objectiveId}/allBuyers`,
				method: "GET",
				query,
			});
		} else {
			res = await api.fetch({
				path: `/api/v2/incentive/${query.incentiveId}/allBuyers`,
				method: "GET",
				query,
			});
		}

		if (res.ok === true) {
			set({
				selectedAccounts: res.payload,
				selectedAccountsTotalPages: res.meta?.totalPages,
			});
			set({ selectedAccountsLoading: false });
		} else {
			console.log("error fetching users");
			set({ selectedAccounts: [] });
			set({ selectedAccountsLoading: false });
		}
	},
	updateAccounts: async (
		isObjective: boolean,
		query: IPaginatedQuery,
		newAccounts: { buyerId: string; checked: boolean }[]
	) => {
		let res;
		if (isObjective) {
			res = await api.fetch({
				path: `/api/v2/objective/${query.objectiveId}/updateAccounts`,
				method: "POST",
				query,
				body: {
					objectiveId: query.objectiveId,
					accounts: newAccounts,
				},
			});
		} else {
			res = await api.fetch({
				path: `/api/v2/incentive/${query.incentiveId}/updateAccounts`,
				method: "POST",
				query,
				body: {
					incentiveId: query.incentiveId,
					accounts: newAccounts,
				},
			});
		}

		if (res.ok === true) {
			console.log("Accounts updated successfully");
		} else {
			console.log("error fetching users");
		}
	},
	updateProducts: async (
		isObjective: boolean,
		query: IPaginatedQuery,
		newProducts: { productId: string; checked: boolean }[]
	) => {
		let res;
		if (isObjective) {
			res = await api.fetch({
				path: `/api/v2/objective/${query.objectiveId}/updateProducts`,
				method: "POST",
				query,
				body: {
					objectiveId: query.objectiveId,
					products: newProducts,
				},
			});
		} else {
			res = await api.fetch({
				path: `/api/v2/incentive/${query.incentiveId}/updateProducts`,
				method: "POST",
				query,
				body: {
					incentiveId: query.incentiveId,
					products: newProducts,
				},
			});
		}

		if (res.ok === true) {
			console.log("Products updated successfully");
		} else {
			console.log("error fetching users");
		}
	},
	fetchAccounts: async (
		query: IQueryFavorite,
		itemId: string,
		userId: string,
		isObjective?: boolean,
		callback?: () => void
	) => {
		if (itemId !== "" || itemId !== undefined) {
			set({ secondaryLoading: true });
			let res;
			if (isObjective) {
				res = await api.fetch({
					path: `/api/v2/objective/${itemId}/buyers/${userId}`,
					method: "GET",
					query,
				});
			} else {
				res = await api.fetch({
					path: `/api/v2/incentive/${itemId}/buyers/${userId}`,
					method: "GET",
					query,
				});
			}

			if (res.ok === true) {
				set({
					accounts: res.payload,
					secondaryTotalPages: res?.meta?.totalPages,
				});
				if (callback) callback();
				set({ secondaryLoading: false });
			} else {
				console.log("error fetching objectives");
				set({ accounts: [] });
				set({ secondaryLoading: false });
			}
		}
	},
	fetchProducts: async (
		query: IQueryFavorite,
		itemId: string,
		userId: string,
		isObjective?: boolean,
		callback?: () => void
	) => {
		if (itemId !== "" || itemId !== undefined) {
			set({ productsLoading: true });
			let res;
			if (isObjective) {
				res = await api.fetch({
					path: `/api/v2/objective/${itemId}/products/${userId}`,
					method: "GET",
					query,
				});
			} else {
				res = await api.fetch({
					path: `/api/v2/incentive/${itemId}/products/${userId}`,
					method: "GET",
					query,
				});
			}

			if (res.ok === true) {
				set({
					products: res.payload,
					productsTotalPages: res?.meta?.totalPages,
				});
				if (callback) callback();
				set({ productsLoading: false });
			} else {
				console.log("error fetching objectives");
				set({ products: [] });
				set({ productsLoading: false });
			}
		}
	},
	fetchTeamMembers: async (query: IGeneralQuery, userId?: string) => {
		const res = await api.fetch({
			path: `/teamMembers/${userId}`,
			method: "GET",
			query,
		});
		if (res.ok === true) {
			set({ teamMembers: res.payload });
		} else {
			set({ teamMembers: [] });
		}
	},
	fetchTeamMembersToRemove: async (
		query: IGeneralQuery,
		userIds?: string[]
	) => {
		const res = await api.fetch({
			path: `/teamMembersForTeamLeads`,
			method: "POST",
			query,
			body: { customIds: userIds },
		});
		if (res.ok === true) {
			set({ teamMembersToRemove: res.payload });
		} else {
			set({ teamMembersToRemove: [] });
		}
	},
}));
