import React from "react";
import {
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@material-ui/core";
import { Item } from "../../types/commonObjIncTypes";
import { ItemDisplayComponent } from "./ItemDisplayComponent";
import { useStyles } from "./ItemsList.styles";
import Pagination from "@material-ui/lab/Pagination";

interface ObjectiveListProps {
	items: Item[];
	currentPage: number;
	totalPages: number;
	loading?: boolean;
	setCurrentPage: (page: number) => void;
	showDeleteButton?: boolean;
	onDelete: (id: string) => void;
	onEdit: (id: string) => void;
	onDuplicate: (id: string) => void;
	onFavorite: (id: string) => void;
	onArchive: (id: string) => void;
	showArchive: boolean;
	onAction: (id: string) => void;
	onReset: (id: string) => void;
	itemType: string;
}

export const ItemList: React.FC<ObjectiveListProps> = ({
	items,
	currentPage,
	totalPages,
	setCurrentPage,
	showDeleteButton = true,
	loading,
	onDelete,
	onEdit,
	onDuplicate,
	onFavorite,
	onArchive,
	showArchive,
	onAction,
	onReset,
	itemType,
}) => {
	const classes = useStyles();

	const type = itemType === "objective" ? "objectives" : "incentives";

	return (
		<>
			<Table className={classes.customTable}>
				<TableHead>
					<TableRow className={classes.tableHead}>
						<TableCell className={classes.customCell}>Name</TableCell>
						<TableCell className={classes.customCell}>Type</TableCell>
						<TableCell className={classes.customCell}>Period</TableCell>
						<TableCell className={classes.customCell}>Actions</TableCell>
					</TableRow>
				</TableHead>
				{!loading && items.length === 0 ? (
					<TableBody>
						<TableRow>
							<TableCell colSpan={4} align="center">
								<Typography
									className={classes.notFound}
									variant="body1"
								>
									No {type} found
								</Typography>
							</TableCell>
						</TableRow>
					</TableBody>
				) : !loading &&(
					<TableBody>
						{items
							.filter((item) => item.archived === showArchive)
							.map((item) => (
								<ItemDisplayComponent
									key={item._id}
									item={item}
									showDeleteButton={showDeleteButton}
									onDelete={onDelete}
									onEdit={() => onEdit(item._id)}
									onFavorite={() => onFavorite(item._id)}
									onDuplicate={() => onDuplicate(item._id)}
									onArchive={() => onArchive(item._id)}
									showActionQueryButton={
										item.type === "quantity" || item.type === "multi" || item.type === "grouped"
									}
									onAction={() => onAction(item._id)}
									onReset={() => onReset(item._id)}
								/>
							))}
					</TableBody>
				)}
			</Table>
			{loading && (
				<div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
					<CircularProgress size={"1rem"} />
				</div>
			)}
			<div style={{ marginTop: 30 }}>
				<Pagination
					count={totalPages}
					page={currentPage}
					onChange={(_, value) => setCurrentPage(value)}
					color="primary"
				/>
			</div>
		</>
	);
};


export default ItemList;
