import { useState } from "react";
import {
	Button,
	DialogContent,
	Dialog,
	DialogTitle,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Divider,
} from "@material-ui/core";
import {
	MeasureState,
	ObjectiveTypeEnum,
	ObjectiveUser,
} from "../../types/objectiveTypes";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { capitalizeFirstLetter } from "../../utils";
import { useStyles } from "./EditComponent.styles";
import {
	CustomObjIncAccountFilters,
	CustomObjIncProductFilters,
	Item,
	MeasureSymbol,
} from "../../types/commonObjIncTypes";
import { NameDescriptionPeriodComponent } from "./nameDescriptionPeriod/NameDescriptionPeriodComponent";
import { PayoutDetails, getPayoutType, initializeMeasures } from "./utils";
import { TargetComponent } from "./targetComponent";
import { PayoutComponent } from "./payoutComponents";
import { ExpandMore } from "@material-ui/icons";
import { ManualAccountsSelection } from "./accountsProductsUsersSelect/accountsSelect/ManualAccountsSelection";
import { QuantityForm } from "../quantityObjective/QuantityForm";
import { useFormContext } from "react-hook-form";
import { CustomAccountsSelection } from "./accountsProductsUsersSelect/accountsSelect/CustomAccountsSelection";
import { CustomProductsSelection } from "./accountsProductsUsersSelect/productsSelect/CustomProductsSelection";
import { IncentiveUser } from "../../types/incentivesTypes";
import SelectionTabs from "./accountsProductsUsersSelect/SelectionTabs";
import ManualProductsSelection from "./accountsProductsUsersSelect/productsSelect/ManualProductsSelection";
import MinimumQuantityComponent from "./minimumQuantityComponent/MinimumQuantityComponent";

interface EditSubItemComponentProps<T> {
	initialSubItem: T;
	open: boolean;
	itemType: "objective" | "incentive";
	onSave: (item: T) => void;
	onClose: () => void;
	parentUsers: any;
}

export const EditSubItemComponent = <T extends Item>({
	initialSubItem,
	itemType,
	open,
	onSave,
	onClose,
	parentUsers,
}: EditSubItemComponentProps<T>) => {
	const classes = useStyles();
	const { handleSubmit } = useFormContext();
	const [subItem, setSubItem] = useState<T>(initialSubItem);
	const [selectionType, setSelectionType] = useState<boolean>(subItem.customAccountsSelected ?? true);

	const handleTabChange = (newValue: boolean) => {
		setSelectionType(newValue);
	};

	const [selectionTypeProd, setSelectionTypeProd] = useState<boolean>(subItem.customProductsSelected ?? true);
	const handleProdTabChange = (newValue: boolean) => {
		setSelectionTypeProd(newValue);
	};
	const [selectedProductsFilters, setSelectedProductsFilters] =
		useState<CustomObjIncProductFilters>(
			subItem.productsFilters || {
				supplier: [],
				brand: [],
				packageType: [],
				premise: [],
				size: [],
				productType: [],
				brandFam: [],
			}
		);
	const [selectedAccountsFilters, setSelectedAccountsFilters] =
		useState<CustomObjIncAccountFilters>(
			subItem.accountsFilters || {
				accountType: [],
				premise: [],
				chain: [],
				location: [],
			}
		);

	const [measures, setMeasures] = useState(initializeMeasures(subItem.measure));
	const [payoutDetails, setPayoutDetails] = useState<PayoutDetails>({
		target: subItem?.target || 0,
		payoutType: subItem?.payoutType || "perAction",
		allOrNothingAmount: subItem?.quantityPayout || 0,
		perActionAmount: subItem?.quantityPayout || 0,
		atRiskAmount: subItem?.quantityPayout || 0,
		threshold: subItem?.threshold || { units: "percentage", value: 0 },
		cap: subItem?.cap || { units: "percentage", value: 100 },
		isOpenEnded: subItem?.isOpenEnded || false,
	});

	const [minQuantityValue, setMinQuantityValue] = useState(subItem.minQuantityValue || 0);

	const handleMinQuantityValueChange = (value: number) => {
		setMinQuantityValue(value);
		setSubItem((prev) => ({
			...prev,
			minQuantityValue: value,
		}));
	};

	const handleMeasureChange = (
		label?: string,
		startDate?: string,
		endDate?: string,
		checked?: boolean,
		symbol?: MeasureSymbol,
		trackingOption?: string,
		trackingPeriod?: number,
		excludeTrackingDays?: string[],
		trackingPeriodStart?: string,
		trackingPeriodEnd?: string,
		referenceTrackingPeriod?: string
	) => {
		setMeasures((prevMeasures) =>
			prevMeasures.map((measure) =>
				measure.label === label
					? {
							...measure,
							startDate,
							endDate,
							checked,
							symbol,
							trackingOption,
							trackingPeriod,
							excludeTrackingDays,
							trackingPeriodStart,
							trackingPeriodEnd,
							referenceTrackingPeriod,
					  }
					: { ...measure, checked: false }
			)
		);
	};

	const handleChange = (name: string, value: string | number) => {
		setSubItem((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const getMeasuresForSave = (measures: MeasureState[]) => {
		return measures.filter((measure: MeasureState) => measure.checked)[0];
	};
	const handleTargetChange = (value: number) => {
		setPayoutDetails((prev) => ({
			...prev,
			target: value,
		}));
	};

	const handleIsOpenEndedChange = (checked: boolean) => {
		setSubItem((prevSubItem) => ({
			...prevSubItem,
			isOpenEnded: checked,
		}));

		setPayoutDetails((prevItem) => ({
			...prevItem,
			isOpenEnded: checked,
			payoutType: "perAction",
		}));
	};

	const handleSubItemSubmit = () => {
		let updatedItem = {
			...subItem,
			measure: getMeasuresForSave(measures),
			payoutType: payoutDetails.payoutType,
			quantityPayout: getPayoutType(payoutDetails) || 0,
			threshold: payoutDetails.threshold,
			cap: payoutDetails.cap,
			payout: payoutDetails.target,
			target: payoutDetails.target,
			isValid: true,
			productsFilters: selectedProductsFilters,
			accountsFilters: selectedAccountsFilters,
			minQuantityValue: minQuantityValue,
			customAccountsSelected: selectionType,
			customProductsSelected: selectionTypeProd
		};
		parentUsers.forEach((parentUser: IncentiveUser | ObjectiveUser) => {
			const existingUser = updatedItem.users?.find(
				({ user }) => user === parentUser.user
			);

			if (!existingUser) {
				updatedItem.users = [
					...(updatedItem.users || []),
					{
						user: parentUser.user,
						images: [],
						quantity: null,
						validation: null,
						completed: false,
						goal: null,
						isEdited: false,
						target: payoutDetails.target,
						payout: getPayoutType(payoutDetails) || 0,
						payoutType: payoutDetails.payoutType,
						isOpenEnded: updatedItem.isOpenEnded,
						cap: updatedItem.cap,
						threshold: updatedItem.threshold,
						minQuantityValue: updatedItem.minQuantityValue,
					},
				];
			}
		});
		onSave(updatedItem);
	};

	if (initialSubItem === undefined) return null;

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="form-dialog-title"
			className={classes.dialogSubObj}
			maxWidth="md"
		>
			<DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
				{`SUB-${itemType.toLocaleUpperCase()}: ${capitalizeFirstLetter(
					subItem.type
				)} ${capitalizeFirstLetter(itemType)}`}
			</DialogTitle>
			<DialogContent className={classes.dialogContentSubObj}>
				<NameDescriptionPeriodComponent
					item={subItem}
					handleChange={handleChange}
				/>
				<>
					<TargetComponent
						name={"Target"}
						target={payoutDetails?.target || 0}
						isOpenEnded={subItem.isOpenEnded}
						onTargetChange={handleTargetChange}
						onIsOpenEndedChange={handleIsOpenEndedChange}
						itemType={subItem.type}
					/>
				</>
				{subItem.type === "imageValidation" && (
					<MinimumQuantityComponent
						minQuantityValue={minQuantityValue}
						onMinQuantityValueChange={handleMinQuantityValueChange}
					/>
				)}
				<Divider className={classes.divider} />
				<div className={classes.flexColumn}>
					<PayoutComponent
						payoutDetails={payoutDetails}
						setPayoutDetails={setPayoutDetails}
					/>
				</div>

				{subItem.type === ObjectiveTypeEnum.Quantity && (
					<QuantityForm
						measures={measures}
						handleMeasureChange={handleMeasureChange}
					/>
				)}

				{subItem.type !== ObjectiveTypeEnum.Multi && (
					<>
						<Accordion>
							<AccordionSummary
								aria-expanded={true}
								expandIcon={<ExpandMore />}
								aria-controls="panel1-content"
								id="panel1-header"
								className={classes.accordeonFontSize}
							>
								Accounts:
							</AccordionSummary>
							<AccordionDetails
								className={classes.flexColumn}
							>
								<SelectionTabs
									selectionType={selectionType}
									setSelectionType={handleTabChange}
								/>
								{selectionType && (
									<CustomAccountsSelection
										selectedAccountsFilters={selectedAccountsFilters}
										setSelectedAccountsFilters={setSelectedAccountsFilters}
									/>
								)}
								{!selectionType && (
									<ManualAccountsSelection item={subItem} />
								)}
							</AccordionDetails>
						</Accordion>

						<Accordion>
							<AccordionSummary
								expandIcon={<ExpandMore />}
								aria-controls="panel1-content"
								id="panel1-header"
								className={classes.accordeonFontSize}
							>
								Products:
							</AccordionSummary>
							<AccordionDetails
								className={classes.flexColumn}
							>
								<SelectionTabs
									selectionType={selectionTypeProd}
									setSelectionType={handleProdTabChange}
								/>
								{selectionTypeProd && (
									<CustomProductsSelection
										selectedProductsFilters={selectedProductsFilters}
										setSelectedProductsFilters={setSelectedProductsFilters}
									/>
								)}
								{!selectionTypeProd && (
									<ManualProductsSelection
										item={subItem}
									/>
								)}
							</AccordionDetails>
						</Accordion>
					</>
				)}
			</DialogContent>
			<div className={classes.buttonsWrapper}>
				<Button onClick={onClose} color="primary">
					Cancel
				</Button>
				<Button onClick={handleSubmit(handleSubItemSubmit)} color="primary">
					{`Save sub-${itemType}`}
				</Button>
			</div>
		</Dialog>
	);
};
