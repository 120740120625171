import { useEffect, useState } from "react";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { objectivesStoreZustand } from "../store/objectivesStore";
import { incentivesStoreZustand } from "../store/incentivesStore";
import { AdvancedPayment } from "../../usersManagement/types/objectiveTypes";

const useUserUpdate = ({ filterType }: { filterType: string }) => {
	const { query } = useQuery<IGeneralQuery>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	const updateUserDetails = async (
		isObjective: boolean,
		target: number,
		payout: number,
		payoutType: string,
		itemId: string,
		userId: string,
		isOpenEnded: boolean,
		threshold: { units: string; value: number },
		cap: { units: string; value: number },
		minQuantityValue: number,
		advancedBudget?: number,
		advancedPayments?: AdvancedPayment[]
	) => {
		if (isObjective) {
			objectivesStoreZustand
				.getState()
				.updateUserTargetAndPayout(
					query,
					itemId,
					userId,
					payoutType,
					payout,
					target,
					isOpenEnded,
					threshold,
					cap,
					minQuantityValue,
					advancedBudget,
					advancedPayments,
					filterType
				);
		} else {
			incentivesStoreZustand
				.getState()
				.updateUserTargetAndPayout(
					query,
					itemId,
					userId,
					payoutType,
					payout,
					target,
					isOpenEnded,
					threshold,
					cap,
					minQuantityValue,
					advancedBudget,
					advancedPayments,
					filterType
				);
		}
	};

	return {
		updateUserDetails,
	};
};

export default useUserUpdate;
