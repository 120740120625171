import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
	centerText: {
		flexGrow: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	list: {
		flexGrow: 1,
		overflowY: "auto",
	},
	listItem: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		borderBottom: "1px solid #e0e0e0",
		paddingBottom: 10,
		marginBottom: 10,
	},
	pagination: {
		display: "flex",
		justifyContent: "center",
		marginTop: 20,
	},
	dialogContent: {
		display: "flex",
		flexDirection: "column",
		maxHeight: "90%",
		overflow: "hidden",
		minHeight: "30rem",
	},
	center: {
		textAlign: "center",
	},
});
