import React from 'react';
import useAccountTypes from '../../../../hooks/useAccountTypes';
import { FormControl } from '@material-ui/core';
import Select from 'react-select';
import { CustomObjIncAccountFilters } from '../../../../types/commonObjIncTypes';
import { useStyles } from './AccountsSelection.styles';

type CustomAccountsSelectionProps = {
	selectedAccountsFilters: CustomObjIncAccountFilters;
	setSelectedAccountsFilters: React.Dispatch<React.SetStateAction<CustomObjIncAccountFilters>>;
};

export const CustomAccountsSelection: React.FC<
	CustomAccountsSelectionProps
> = ({ selectedAccountsFilters, setSelectedAccountsFilters }) => {
	const classes = useStyles();
	const { accountTypes, chains, locations } = useAccountTypes();

	const premises = ['On Premise', 'Off Premise'];

	const handleFilterChange = (filterType: string, values: string[]) => {
		setSelectedAccountsFilters((prevState: any) => ({
			...prevState,
			[filterType]: values
		}));
	};

	return (
		<div className={classes.container}>
			<FormControl variant="outlined" className={classes.marginTop}>
				<Select
					isMulti
					name="accountTypes"
					placeholder="Select Account Types"
					options={accountTypes.map((type) => ({
						value: type,
						label: type,
					}))}
					value={selectedAccountsFilters.accountType?.map(type => ({
						value: type,
						label: type
					}))}
					onChange={multi => handleFilterChange('accountType', multi.map((type: any) => type.value))}
				/>
			</FormControl>

			<FormControl variant="outlined" className={classes.marginTop}>
				<Select
					isMulti
					name="premises"
					placeholder="Select Premise Types"
					options={premises.map((type) => ({
						value: type,
						label: type,
					}))}
					value={selectedAccountsFilters.premise?.map(type => ({
						value: type,
						label: type
					}))}
					onChange={multi => handleFilterChange('premise', multi.map((type: any) => type.value))}
				/>
			</FormControl>
			<FormControl variant="outlined" className={classes.marginTop}>
				<Select
					isMulti
					name="chains"
					placeholder="Select Chains"
					options={chains.map((type) => ({
						value: type,
						label: type,
					}))}
					value={selectedAccountsFilters.chain?.map(type => ({
						value: type,
						label: type
					}))}
					onChange={multi => handleFilterChange('chain', multi.map((type: any) => type.value))}
				/>
			</FormControl>
			<FormControl variant="outlined" className={classes.marginTop}>
				<Select
					isMulti
					name="locations"
					placeholder="Select Locations"
					options={locations.map((type) => ({
						value: type,
						label: type,
					}))}
					value={selectedAccountsFilters.location?.map(type => ({
						value: type,
						label: type
					}))}
					onChange={multi => handleFilterChange('location', multi.map((type: any) => type.value))}
				/>
			</FormControl>
		</div>
	);
};
